@charset 'utf-8';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@include foundation-global-styles;
@include foundation-grid;
@include foundation-flex-grid;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;
@include foundation-flex-classes;
// @include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;
/* STYLES */

.cell {
    //border: #61a7fbff solid 1px;
    /* padding: 10px; */
    background: #ffffff; //#39636864;
    padding-bottom: 0;
    box-shadow: 0 0 0.30rem #e4e4e493;
    border-radius: 5px;
    border: #9bc5ff46 solid 2px;
    transition: all 0.2s ease;
}

.cell:hover {
    /*  animation: shadow  0.5s  ease ; */
    box-shadow: 0 2px 0.60rem #4f708052;
}

.padding-top {
    padding-top: 15px;
}

/* 
.cl1{
    background: #375e8a;
}

.cl2{
    background: #37848a;
}

.cl3{
    background: #378a78;
}

.cl4{
    background: #378a55;
}

.cl5{
    background: #4f8a37;
}

.cl6{
    background: #7b8a37;
}

.cl7{
    background: #8a3f37;
} */

img {
    width: 100%;
    height: auto;
}

.icon {
    width: 40%;
    height: auto;
}

.txt {
    font-size: 14px;
    word-wrap: normal;
}

.txt-1 {
    font-size: 14px;
    font-weight: bold;
}

h1 {
    font-size: 40px;
    line-height: 40px;
    color: #333;
}

h2 {
    font-size: 14px;
    color: #444;
}

.center {
    text-align: center;
}

.logo {
    padding-top: 10px;
    width: 30%;
}

.h1_head {
    color: #61a7fbff;
}

.footer {
    //background: #497bb8;
    margin-top: 20px;
}

.txt-f {
    text-align: center;
    font-size: 10px;
    color: rgb(0, 0, 0);
    padding: 10px;
}

body {
    background: #f9fffe;
}

.grid-container {
    background: #ffffff;
}

.link {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-bottom: 0;
}

a:focus {
    outline: none;
}

a {
    outline: none;
}

* {
    outline: none;
}

.main-menu {
    padding-top: 15px;
}

hr {
    clear: both;
    max-width: 100rem;
    height: 0;
    margin-top: 5px;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #aacbff;
    border-left: 0;
}

footer {
    text-align: right;
    img {
        width: 100px;
    }
}

.ill-2 {
    width: 90%;
}